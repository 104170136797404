import { html, render } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { ref, createRef } from 'lit/directives/ref.js';
import icons from '../foundation/icons.js';
import MProduct from './m-product.js';
import OLightbox from './o-lightbox.js';

const translations = JSON.parse(document.getElementById('api-translations')?.innerText ?? '{}');

class MProductType {
	/** Storage for fetched data */
	storage = {};

	/** Store for promise of fetch request */
	fetchDataPromise = null;

	/** API path to fetch data from */
	apiPath = '/api/catalog/product-type';

	isHidden = true;

	/**
	 * @type {("standalone" | "overview")}
	 * The variant type, can be either 'standalone' or 'overview'.
	 */
	variant = null;

	#duration = 0;

	/** Used for button animation */
	#durationSmall = 0;

	// Refs
	detailsRef = createRef();

	productsRef = createRef();

	detailsProductsRef = createRef();

	techImageRef = createRef();

	footerRef = createRef();

	buttonCloseRef = createRef();

	/** @param {HTMLElement} element */
	constructor(element) {
		// Bind instance properties to DOM elements
		this.element = element; // The main element
		this.id = element.dataset.id; // ID of the element
		this.uid = element.dataset.uid; // ID of the element
		this.title = element.ariaLabel;
		this.systemId = element.dataset.system;
		this.assortment = element.dataset.assortment;
		this.variant = element.getAttribute('data-variant') ?? null;

		this.linkElement = element.querySelector('.m-product-type__link');
		this.imagesElement = element.querySelector('.m-product-type__images');
		this.productImageElement = element.querySelector('.m-product-type__product-image');
		this.productImageImgElement = this.productImageElement?.querySelector(':scope > img');

		this.productImageImgOriginalSizes = this.productImageImgElement?.getAttribute('sizes');

		let duration = parseFloat(getComputedStyle(this.element).getPropertyValue('--_duration'));
		duration = Number.isFinite(duration) ? duration : 200;
		this.#duration = duration;

		let durationSmall = parseFloat(getComputedStyle(this.element).getPropertyValue('--duration-small'));
		durationSmall = Number.isFinite(durationSmall) ? durationSmall : 160;
		this.#durationSmall = durationSmall;

		let url = `${document.location.origin}${document.location.pathname}`;
		if (this.variant === null) {
			url += `#${this.id}`;
		}

		this.linkElement?.setAttribute('role', 'button');

		this.onClickCopyLink = async () => {
			try {
				await navigator.clipboard.writeText(url);
				const toast = Object.assign(document.createElement('vi-toast'), {
					innerText: translations['saved-to-clipboard'],
				});
				document.querySelector('.m-toasts')?.prepend(toast);
			} catch (error) {
				console.error(error);
			}
		};

		this.onClickShare = async () => {
			try {
				await navigator.share({
					title: this.title,
					url,
				});
			} catch (error) {
				console.error(error);
			}
		};

		// Event Listener
		if (this.variant === null) {
			element.addEventListener('click', () => {
				this.extend();
			}); // Show the details on click
			element.addEventListener('mouseenter', () => {
				// Prefetch data on mouse enter, but only once per element
				this.data;
			}, {
				once: true,
			});

			document.addEventListener('keydown', (event) => {
				if (event.key === 'Escape') {
					this.reduce();
				}
			});

			if (document.location.hash === `#${this.id}`) {
				this.extend();
				this.element.scrollIntoView({
					behavior: 'smooth',
				});
			}
		}
		this.productImageElement?.addEventListener('click', this.onImageClick.bind(this));
		this.linkElement?.addEventListener('click', (event) => {
			event.preventDefault();
			this.extend();
		});

		if (this.variant === 'standalone') {
			this.initStandalone();
		}
	}

	/**
	 * Initialize additional event listeners
	 */
	initStandalone() {
		const { element } = this;

		const mProductElements = element.querySelectorAll('.m-product');
		[...mProductElements].forEach((_) => new MProduct(_));

		const buttonShareElement = element.querySelector('.a-button[data-action="share"]');

		element.querySelector('.m-product-type__tech-image').addEventListener('click', this.onImageClick.bind(this));
		element.querySelector('.a-button[data-action="copy-link"]')?.addEventListener('click', this.onClickCopyLink.bind(this));

		if (typeof navigator.share !== 'function') {
			buttonShareElement.remove();
		} else {
			buttonShareElement.addEventListener('click', this.onClickShare.bind(this));
		}
	}

	// Define a getter method for 'data' property
	get data() {
		const {
			uid,
			fetchDataPromise,
		} = this;
		const {
			data = null,
		} = this.storage;
		// If data is already fetched, return a promise that resolves to the data
		if (data !== null && typeof data === 'object') {
			return Promise.resolve(data);
		}
		// If fetch request is in progress, return the promise of the fetch request
		if (fetchDataPromise !== null && typeof fetchDataPromise === 'object') {
			return fetchDataPromise;
		}
		// Fetch data from the API and store the promise of the fetch request
		// Also, store the fetched data in 'storage' property
		this.fetchDataPromise = fetch(`${this.apiPath}/${uid}`, {
			headers: {
				accept: 'application/json',
				'x-language': document.documentElement.lang,
				'x-assortment': document.querySelector('main').dataset.assortment,
			},
			mode: 'same-origin',
			credentials: 'same-origin',
			cache: 'no-cache',
		}).then((response) => response.json())
			.then((jsonData) => {
				this.storage.data = jsonData.data;
				return this.storage.data;
			})
			.catch((error) => {
				// Handle any errors during fetch
				console.error(error);
			});
		return this.fetchDataPromise;
	}

	get filterObject() {
		return JSON.parse(this.element.dataset.filterObject);
	}

	shouldShow(userFilter = {}) {
		const { filterObject } = this;
		return Object.entries(userFilter).every(([key, value]) =>
			filterObject.hasOwnProperty(key) && filterObject[key] === value);
	}

	updateAssortmentLinks(hash) {
		const ulElement = document.querySelector('[data-id="filter-assortment"]');
		if (ulElement) {
			ulElement.querySelectorAll('a').forEach((aElement) => {
				const url = new URL(aElement.href);
				url.hash = `#${hash}`;
				aElement.setAttribute('href', url.href);
			});
		}
	}

	onImageClick(event) {
		event.preventDefault();
		if (event.currentTarget.ariaDisabled !== 'true') {
			new OLightbox(event.currentTarget);
		}
	}

	get footerElement() {
		return html`
			<div class="m-product-type__footer" ${ref(this.footerRef)}>
				<button class="a-button" data-kind="tertiary" data-size="small" @click=${this.onClickCopyLink}>
					${unsafeHTML(icons.link)}
					${translations['copy-direct-link']}
				</button>
				${typeof navigator.share === 'function' ? html`
					<button class="a-button" data-kind="tertiary" data-size="small" @click=${this.onClickShare}>
						${unsafeHTML(icons.share)}
						${translations['product.share']}
					</button>
				` : null}
			</div>
		`;
	}

	appearElement(element, index, elementHeight, delay = 0) {
		const height = elementHeight ?? `${element.scrollHeight}px`;
		const marginBlockStart = getComputedStyle(element).getPropertyValue('margin-block-start');
		const marginBlockEnd = getComputedStyle(element).getPropertyValue('margin-block-end');
		element.animate([
			{
				height: 0,
				marginBlockStart: 0,
				marginBlockEnd: 0,
				opacity: 0,
			},
			{
				marginBlockStart,
				marginBlockEnd,
				height,
				opacity: 0,
				translate: `0 -${4 * index}px`,
			},
			{
				opacity: 1,
				translate: '0 0',
			},
		], {
			duration: this.#duration * 2,
			delay,
			fill: 'backwards',
			easing: 'ease-out',
		});
	}

	disappearElement(element, index, elementHeight) {
		const height = elementHeight ?? `${element.scrollHeight}px`;
		const marginBlockStart = getComputedStyle(element).getPropertyValue('margin-block-start');
		const marginBlockEnd = getComputedStyle(element).getPropertyValue('margin-block-end');
		return element.animate([
			{
				opacity: 1,
			},
			{
				marginBlockStart,
				marginBlockEnd,
				height,
				opacity: 0,
				translate: '0 0',
			},
			{
				marginBlockStart: 0,
				marginBlockEnd: 0,
				height: 0,
				opacity: 0,
				translate: `0 -${4 * index}px`,
			},
		], {
			duration: this.#duration * 1,
			easing: 'ease-out',
		});
	}

	// Define a method to extend the details
	extend() {
		if (!this.element.hasAttribute('data-extended')) {
			(async () => {
				this.isHidden = false;

				// Wait for DOM to be generated
				await this.render()
					.catch((error) => {
						// Handle the error here
						console.error(error);
					});

				// Animate root element
				this.element.toggleAttribute('data-extended', true);

				// Update attributes
				this.productImageImgElement?.setAttribute('sizes', '230px');
				this.productImageElement?.removeAttribute('aria-disabled');

				// Animate elements
				let index = 0;
				if (this.techImageRef.value) {
					const height = getComputedStyle(this.element).getPropertyValue('--_image-size-extended');
					this.appearElement(this.techImageRef.value, index += 1, height, this.#duration * 1);
				}
				if (this.detailsRef.value) {
					this.appearElement(this.detailsRef.value, index += 1);
				}
				if (this.productsRef.value) {
					this.appearElement(this.productsRef.value, index += 1);
				}
				if (this.detailsProductsRef.value) {
					this.appearElement(this.detailsProductsRef.value, index += 1);
				}
				if (this.footerRef.value) {
					this.appearElement(this.footerRef.value, index += 1);
				}
				if (this.buttonCloseRef.value) {
					this.buttonCloseRef.value.animate([
						{ opacity: 0, scale: 0 },
						{ opacity: 1, scale: 1 },
					], { duration: this.#durationSmall });
				}

				this.productImageElement.removeAttribute('tabindex');

				// Update location and links
				window.location.replace(`#${this.id}`);
				this.updateAssortmentLinks(this.id);
			})();
		}
	}

	// Define a method to reduce the details
	reduce() {
		// Hide all elements
		if (this.element.hasAttribute('data-extended')) {
			// Animate root element
			this.element.removeAttribute('data-extended');

			// Update attributes
			this.productImageImgElement?.setAttribute('sizes', this.productImageImgOriginalSizes);
			this.productImageElement?.setAttribute('aria-disabled', 'true');

			const extendedHeight = this.element.offsetHeight;

			let latestAnimation = null;
			// Animate elements
			if (window.matchMedia('(min-width: 23.75em)').matches) {
				let index = 0;
				if (this.techImageRef.value) {
					latestAnimation = this.disappearElement(this.techImageRef.value, index += 1);
				}
				if (this.detailsRef.value) {
					latestAnimation = this.disappearElement(this.detailsRef.value, index += 1);
				}
				if (this.productsRef.value) {
					latestAnimation = this.disappearElement(this.productsRef.value, index += 1);
				}
				if (this.detailsProductsRef.value) {
					latestAnimation = this.disappearElement(this.detailsProductsRef.value, index += 1);
				}
				if (this.footerRef.value) {
					latestAnimation = this.disappearElement(this.footerRef.value, index += 1);
				}
				if (this.buttonCloseRef.value) {
					this.buttonCloseRef.value.animate([
						{ opacity: 1, scale: 1 },
						{ opacity: 0, scale: 0 },
					], { duration: this.#durationSmall, fill: 'forwards' });
				}
			} else if (this.buttonCloseRef.value) {
				// Avoid big fade out animations on small screens
				latestAnimation = this.buttonCloseRef.value.animate([
					{ opacity: 1, scale: 1 },
					{ opacity: 0, scale: 0 },
				], { duration: this.#duration, fill: 'forwards' });
			}

			this.productImageElement.setAttribute('tabindex', '-1');

			// Update location and links
			window.location.replace('#!');
			this.updateAssortmentLinks('!');

			latestAnimation.addEventListener('finish', async () => {
				if (!this.element.hasAttribute('data-extended')) {
					this.isHidden = true;
					await this.render();

					if (window.matchMedia('not (min-width: 23.75em)').matches) {
						// Avoid content shift on small screens.
						window.scrollBy({
							top: (extendedHeight - this.element.offsetHeight) * -1,
							behavior: 'instant',
						});
					}
				}
			});
		}
	}

	toggle() {
		// If details are currently extended, reduce them; otherwise, extend them
		if (this.element.hasAttribute('data-extended')) {
			this.reduce();
		} else {
			this.extend();
		}
	}

	// used by m-filter-catalog.js
	show() {
		this.element.toggleAttribute('hidden', false);
	}

	// used by m-filter-catalog.js
	hide() {
		this.element.toggleAttribute('hidden', true);
	}

	async render() {
		const {
			element,
			imagesElement,
			isHidden,
			systemId,
			assortment,
		} = this;

		// Wait for data to be fetched
		const data = await this.data;

		// prepare data
		const {
			details = [],
			detailsProducts = [],
			productGroups = [],
			techImage = null,
		} = data;

		// Events
		const onButtonCloseClick = (event) => {
			event.stopPropagation(); // Stop the click event from bubbling up to parent elements
			this.reduce(); // Reduce the details on click
		};

		render(html`
			${techImage && !isHidden ? html`
				<a
					href="${techImage.src}"
					role="button"
					class="m-product-type__tech-image"
					${ref(this.techImageRef)}
					@click="${this.onImageClick}"
				>
					<img
						src="${techImage.src}"
						srcset="${techImage.srcset ?? ''}"
						width="${techImage.width ?? ''}"
						height="${techImage.height ?? ''}"
						alt="${techImage.alt ?? ''}"
						sizes="230px"
					>
				</a>
			` : null}
		`, imagesElement);

		render(html`
			${!isHidden ? html`
				<div class="m-product-type__button-close" ${ref(this.buttonCloseRef)}>
					<button @click="${onButtonCloseClick}" class="a-button" data-kind="tertiary" data-shape="round" data-size="x-large">
						${unsafeHTML(icons.close)}
						<span class="a-visually-hidden">
							${translations['product-type.button.close']}
						</span>
					</button>
				</div>
				${details.length !== 0 ? html`
					<div class="m-product-type__details" ${ref(this.detailsRef)}>
						${details.map((detail) => html`
							<div class="m-product-type__detail ${detail.class ? `-${detail.class}` : ''}">
								${unsafeHTML(detail.text)}
							</div>
						`)}
					</div>
				` : null}
				<div class="m-product-type__products m-products" ${ref(this.productsRef)}>
					${productGroups.map((productGroup) => html`
						<h3>${productGroup.title}</h3>
						<ul role="list">
							${productGroup.products.map((product) => html`
								<li>
									${html`${(new MProduct(product, systemId, assortment)).element}`}
								</li>
							`)}
						</ul>
					`)}
				</div>
				${detailsProducts.length !== 0 ? html`
					<div class="m-product-type__details -products" ${ref(this.detailsProductsRef)}>
						${detailsProducts.map((detail) => html`
							<div class="m-product-type__detail ${detail.class ? `-${detail.class}` : ''}">
								${unsafeHTML(detail.text)}
							</div>
						`)}
					</div>
				` : null}
				${html`${this.footerElement}`}
			` : null}
		`, element);
	}
}

const mProductTypes = [...document.querySelectorAll('.m-product-type')].map((element) => new MProductType(element));

export default mProductTypes;
